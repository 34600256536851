<template>
<div>
	<div class="page-container">
	    <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
	    <div class="page-desc">
	       该页面展示耗材所有仓库和批次的物料库存信息，包含可用库存、冻结库存、总库存、总金额、单价等
	     </div>  
		  <div class="search-form">
		  	<el-form ref="form" :inline="true"  :model="search_key" prop="">
				<el-form-item label="">
			<!-- 		<el-checkbox-group v-model="checked">
					   <el-checkbox  :label="1">按批次统计</el-checkbox>
					   <el-checkbox  :label="2">按仓库统计</el-checkbox>
					 </el-checkbox-group> -->
					<el-radio-group v-model="checked" @change="handleCount">
					   <el-radio :label="1">按批次统计</el-radio>
					   <el-radio :label="2">按仓库统计</el-radio>
					 </el-radio-group>
				 </el-form-item>
		  		<el-form-item label="">
		  			<el-input v-model="search_key.keyword" placeholder="输入关键字搜索"></el-input>
		  		</el-form-item>
				<el-button type="primary" @click="getData()">查询</el-button>
				<el-button type="primary" @click="handleExport()">导出全部</el-button>
				<el-button type="primary" @click="handleAdd('耗材入库单','CseStorage/add')">耗材入库</el-button>
				<el-button type="primary" @click="handleAdd('耗材派发单','CseDist/add',2)">耗材派发</el-button>
		  	</el-form>
		   </div>
		<el-table
		       :data="tableData" 
		       style="width:100%">
		      <!--  <el-table-column
		           prop="id"
		           label="ID">
		       </el-table-column> -->
		       <el-table-column   
		           prop="mtl_code"
		           label="物料编码">
		       </el-table-column>
			   <el-table-column
					v-if="checked == 2"
			       prop="location_name"
			       label="所在仓库">
			   </el-table-column>
			   <el-table-column
			   		v-if="checked == 1"
			       prop="batch_number"
				   sortable
			       label="批次号"> 
			   </el-table-column>
			<!--   <el-table-column
					empty-text='-'
			       prop="batch_number"
			       label="批次号">
			   </el-table-column> -->
			   <el-table-column
			       prop="name"
			       label="物料名称">
			   </el-table-column>
			   <el-table-column
			       prop="name"
			       label="物料分类">
			   </el-table-column>
			   <el-table-column
			       prop="stock_sum"
				    empty-text='-'
					sortable
			       label="可用库存">
			   </el-table-column>
			   <el-table-column
			       prop="blocked_stock_sum"
				    empty-text='-'
					sortable
			       label="冻结库存">
			   </el-table-column>
			   <el-table-column
			       prop="count"
			   		empty-text='-'
					sortable
			       label="总库存">
				   <template slot-scope="scope">
					   <span>{{parseInt(Number(scope.row.stock_sum) + Number(scope.row.blocked_stock_sum))}}</span>
				   </template>
			   </el-table-column>
			   <el-table-column
			       prop="total_prices_sum"
			   		empty-text='0.00'
					sortable
			       label="总金额">
			   </el-table-column>
				<el-table-column
				    prop="unit_price"
					empty-text='0.00'
					sortable
				    label="单价">
				</el-table-column>
	<!-- 		   <el-table-column
			       prop="prod_date"
			   		empty-text='-'
			       label="生产日期">
			   </el-table-column> 
			   <el-table-column
			       prop="wty_period"
			   		empty-text='-'
			       label="保质期(月)">
			   </el-table-column> 
			   <el-table-column
			       prop="exp_date"
			   		empty-text='-'
			       label="到期时间">
			   </el-table-column> -->
		    <!--   <el-table-column
		       fixed="right"
		       width="240px"
		       label="操作">
		       <template slot-scope="scope">
		           <el-button   class="btn"  size="small" @click="handleAdd(scope.row.id)">编辑</el-button>
		       </template>
		       </el-table-column> --> 
		</el-table>
		<pagination-view :table-data-total='total' @currPage='getCurrPage' @pageSize='getPageSize'></pagination-view> 
		 <AddVue ref="childDialog" ></AddVue>
	</div>   
</div>
</template>

<script>
import AddVue from '../Common/ConsumableAdd.vue'
export default {
	 inject:['reload'],
	 components:{
	     AddVue,
	 },
    data() {
        return {
			tableData:[],
			currentPage:1,
			pagesize:10,
			total:0,
			search_key:{
				keyword:''
			},
			checked:2
        }
    },
    created() {
		this.getData()
    },
    mounted() { 

    },
    methods:{
		getData(){
		    var arr  =[]
		    this.$api.post('CseStock/index',{
				page:this.currentPage,
				limit:this.pagesize,
				keyword:this.search_key.keyword,
				count_type:this.checked
				
			},res=>{
		        if(res.code == 200){
		            this.tableData = res.data.list??[]
		            this.total  = res.data.count
		        }
		    })
		},
		handleAdd(name,add_url,type=1){
			this.$nextTick(()=>{
				this.$refs.childDialog.cardName = name
				this.$refs.childDialog.addUrl  = add_url
				this.$refs.childDialog.storageType = type	
				this.$refs.childDialog.handleAdd()
			})
		},
		
		//监听统计类型
		handleCount(val){
			this.getData()
		},
		handleExport(){
			location.href = '/api/admin/CseStock/export?count_type='+this.checked
		},
		
		getCurrPage(val){   //获取当前页
		    this.currentPage =  val
		    this.getData()
		},
		getPageSize(val){   //获取每页条数
		    this.pagesize =  val
		    this.getData()
		},
    },
}
</script>
<style scoped>

</style>